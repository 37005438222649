import React from "react";
import "./Reviews.css";

function Reviews() {
  const reviews = [
    {
      id: 1,
      name: "Hujjat Rahman",
      avatar: "/assets/reviews/hujjat.jpg",
      rating: 5,
      isVideo: true,
      date: "Oct 5, 2024",
      text: "Amazing analysis with amazing personality, I never seen someone like you who is serious and giving this type of dedication level to help others",
    },
    {
      id: 2,
      name: "Bablu Debnath",
      avatar: "/assets/reviews/bablu.jpg",
      rating: 4,
      isVideo: true,
      date: "Oct 3, 2024",
      text: "Great course, Beginner to Advance, Tapas Sir, you are great awesome knowledge given in this course",
    },
    {
      id: 3,
      name: "Parvez Alam",
      initials: "PA",
      rating: 5,
      date: "Oct 29, 2024",
      text: "Bhai, you are awesome, and the best part is that you are very down to earth, i really respect your attitude.",
    },
    {
      id: 4,
      name: "kutubuddin ali",
      avatar: "/assets/reviews/kutubuddin.jpg",
      rating: 5,
      date: "Oct 21, 2024",
      text: "Thank you for being such a calm and knowledgeable mentor. The forex course has been incredibly valuable, and your clear guidance has made market analysis much easier to grasp. I appreciate all your efforts in teaching us.",
    },

    {
      id: 4,
      name: "kutubuddin ali",
      avatar: "/assets/reviews/kutubuddin.jpg",
      rating: 5,
      date: "Oct 21, 2024",
      text: "Thank you for being such a calm and knowledgeable mentor. The forex course has been incredibly valuable, and your clear guidance has made market analysis much easier to grasp. I appreciate all your efforts in teaching us.",
    },

    {
      id: 4,
      name: "Shah Rukh Khan",
      avatar: "/assets/reviews/kutubuddin.jpg",
      rating: 5,
      date: "aug 21, 2024",
      text: ". The forex course has been incredibly valuable, and your clear guidance has made  all your efforts in teaching us.",
    },

    {
      id: 4,
      name: "Salman Khan",
      avatar: "/assets/reviews/kutubuddin.jpg",
      rating: 5,
      date: "Feb 1, 2024",
      text: " The forex course has been incredibly valuable,",
    },
    {
      id: 4,
      name: "Amir Khan",
      avatar: "/assets/reviews/kutubuddin.jpg",
      rating: 5,
      date: "July 21, 2021",
      text: "I never seen someone like you who is serious and giving this type of dedication level to help others",
    },
  ];

  return (
    <div className="reviews-page">
      <div className="reviews-container">
        <h1>Customer Reviews</h1>
        <p className="reviews-subtitle">
          See what our community has to say about their experience
        </p>

        <button className="submit-review-btn">
          <span className="icon">↓</span>
          Submit Your Review
        </button>

        <div className="testimonials-grid">
          {reviews.map((review) => (
            <div key={review.id} className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-info">
                  {review.avatar ? (
                    <img
                      src={review.avatar}
                      alt={review.name}
                      className="avatar-img"
                    />
                  ) : (
                    <div className="avatar">{review.initials}</div>
                  )}
                  <div className="name">{review.name}</div>
                </div>

                <div className="rating">{"⭐️".repeat(review.rating)}</div>
              </div>
              <p className="testimonial-text">{review.text}</p>
              <div className="review-date">{review.date}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Reviews;
