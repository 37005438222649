import React from 'react';
import './Hero.css';
import tradingDashboard from '../images/trading-dashboard.svg';

function Hero() {
  return (
    <>
      <section className="hero">
        <div className="hero-container">
          <div className="hero-content">
            <h1>Learn to Trade<br />like a Legend</h1>
            <p>If you're new to cryptocurrency, my crypto course will provide you with a solid foundation to start your trading journey.</p>
            
            <div className="hero-buttons">
              <button className="buy-course-btn">BUY THE COURSE</button>
              <button className="reviews-btn">OUR REVIEWS</button>
            </div>
          </div>
          
          <div className="hero-image">
            <img 
              src={tradingDashboard} 
              alt="Trading Dashboard Illustration"
              className="dashboard-illustration" 
            />
          </div>
        </div>
      </section>

      <section className="features">
        <div className="features-container">
          <h2>Supercharge Your Crypto Trading Knowledge</h2>
          
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">📊</div>
              <h3>X Hours of In-Depth Videos</h3>
              <p>Explore every aspect of Trading from the basics to the most advanced levels with our action-packed video lessons.</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">🎯</div>
              <h3>Beginners to Advanced</h3>
              <p>Gain insights through two live trades, designed to illustrate the trading process for both novice and experienced traders.</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">🚀</div>
              <h3>Range Calculation Maths</h3>
              <p>We explore the precise mathematical calculations underlying the range determination for key cryptocurrency pairs</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">💬</div>
              <h3>Q&A Session Included</h3>
              <p>One Q&A session to address your questions & help you with your challenges you encounter during your crypto trading activities</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">👥</div>
              <h3>Private Community</h3>
              <p>This gives you free access to our private exclusive community and gain first yearly access to advanced range calculations.</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">🔒</div>
              <h3>Lifetime Access</h3>
              <p>Secure permanent access to the current video library, with the option to receive future updates at an additional cost.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero; 