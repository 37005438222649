import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar-wrapper">
      <Link to="/" className="logo-link">
        <span className="logo-text">LegendPro</span>
      </Link>

      <div className="nav-menu">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/course">Course</Link>
        <Link to="/reviews">Reviews</Link>
        <Link to="/contact">Contact</Link>
      </div>

      <Link to="/bundle" className="bundle-btn">
        Buy the Bundle
      </Link>
    </div>
  );
};

export default Navbar;
