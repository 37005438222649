import React from "react";
import { Link } from "react-router-dom";
import "./Course.css";

const Course = () => {
  return (
    <div className="course-container">
      <main className="course-content">
        <h1 className="course-title">Crypto Master Class</h1>

        <p className="course-description">
          Unlock the full potential of your crypto and forex trading, using the
          most wanted range calculation strategy by the trading legend.
        </p>

        <div className="action-buttons">
          <Link
            to="https://apps.apple.com/us/app/metatrader-5/id413251709"
            className="action-button"
          >
            iOS App
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.forex.pro&hl=en"
            className="action-button"
          >
            Android App
          </Link>
          <Link to="/contact" className="action-button">
            Contact
          </Link>
        </div>

        <div className="order-section">
          <button className="order-button inr-button">
            <span className="lightning">⚡</span> ORDER NOW ₹49,999 (Credit &
            Debit Cards)
          </button>

          <button className="order-button crypto-button">
            <span className="lightning">⚡</span> ORDER NOW 600 USDT (Crypto
            TRC20)
          </button>

          <div className="limited-offer">
            <span className="fire">🔥</span> LIMITED TIME OFFER{" "}
            <span className="fire">🔥</span>
            INDIAN STOCK MARKET COURSE WORTH ₹29,999 INCLUDED FOR FREE!
          </div>
        </div>

        <section className="benefits-section">
          <h2 className="benefits-title">Access Benefits Included Perks</h2>

          <div className="benefits-grid">
            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-video"
                >
                  <rect
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="2.18"
                    ry="2.18"
                  ></rect>
                  <line x1="7" y1="2" x2="7" y2="22"></line>
                  <line x1="17" y1="2" x2="17" y2="22"></line>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>Multiple Video Chapters</h3>
                <p>Over 13 Hours of Videos & More Uploading soon</p>
              </div>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-chat"
                >
                  <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>In App Private Group</h3>
                <p>Live Updates on Crypto Forex Gold Markets</p>
              </div>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-target"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <circle cx="12" cy="12" r="6"></circle>
                  <circle cx="12" cy="12" r="2"></circle>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>Indian Market Course</h3>
                <p>
                  Get free access to the upcoming videos about the Indian
                  Market.
                </p>
              </div>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-chart"
                >
                  <path d="M3 3v18h18"></path>
                  <path d="M18 17V9"></path>
                  <path d="M13 17V5"></path>
                  <path d="M8 17v-3"></path>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>Private Indicators</h3>
                <p>Get access to my customized private indicator.</p>
              </div>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-bell"
                >
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>Monthly Q&A Sessions</h3>
                <p>Monthly one Q&A Session for next one year.</p>
              </div>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="icon-infinity"
                >
                  <path d="M12 12c2-2.96 0-7-1-8c0 3.038-1.773 4.741-3 6c-1.226 1.26-2 3.24-2 5a6 6 0 1 0 12 0c0-1.532-1.056-3.94-2-5c-1.786 3-2 5-4 2z"></path>
                </svg>
              </div>
              <div className="benefit-content">
                <h3>Lifetime Access</h3>
                <p>
                  Access the video lessons for lifetime and watch anytime
                  anywhere.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Course;
