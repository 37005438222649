import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Privacy from "./Privacy";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logos">
              <img
                src="../public/assets/footer-logo-left-1.png"
                alt="Logo 1"
                className="footer-logo"
              />
              <img
                src="/assets/footer-logo-left-2.png"
                alt="Logo 2"
                className="footer-logo"
              />
            </div>
          </div>
          <div className="footer-center">
            <img
              src="/assets/footer-logo-center.png"
              alt="DMCA Protected"
              className="dmca-logo"
            />
          </div>
          <div className="footer-right">
            <p className="footer-tagline">
              Level up your crypto game with the trading legend course bundle
            </p>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-links">
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/refund">Refund Policy</Link>
          </div>
          <div className="footer-info">
            <div className="copyright">2024 © Legend Pro FZ LLC</div>
            <div className="social-connect">
              <span>Connect with us</span>
              <div className="social-icons">
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://threads.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-threads"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
