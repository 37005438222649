import React from 'react';
import './Features.css';

function Features() {
  return (
    <section className="features" id="features">
      <div className="container">
        <h2>Access Benefits Included Perks</h2>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">📚</div>
            <h3>30+ Video Chapters</h3>
            <p>Videos incoming soon once pre order ends.</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">👥</div>
            <h3>Private Group Access</h3>
            <p>Live Updates on Crypto Forex Gold Markets</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">📊</div>
            <h3>Private Indicators</h3>
            <p>Get access to my customized private indicator.</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">🎓</div>
            <h3>Monthly Q&A Sessions</h3>
            <p>Monthly one Q&A Session for next one year.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features; 