import React from 'react';
import './AppDownload.css';

function AppDownload() {
  return (
    <section className="app-download">
      <div className="wave-background"></div>
      <div className="app-download-container">
        <h2>Download my App &<br />Access the Videos!</h2>
        
        <p>Access the course videos, private channel and all the updates related to crypto and indian markets at one single place on your Android & iPhone.</p>
        
        <div className="app-buttons">
          <button className="app-btn android">Android App</button>
          <button className="app-btn ios">iPhone App</button>
        </div>
      </div>
    </section>
  );
}

export default AppDownload; 