import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy for ALT Trader</h1>
      <p className="effective-date">Effective Date: March 14, 2024</p>

      <section className="privacy-section">
        <h2>Introduction</h2>
        <p>
          Welcome to ALT Trader. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you use our mobile
          application, which offers courses related to QuoteX trading,
          cryptocurrency trading, and forex trading. Please read this policy
          carefully. If you do not agree with the terms, please do not access
          the app.
        </p>
      </section>

      <section className="privacy-section">
        <h2>1. Information We Collect</h2>
        <h3>1.1 Personal Information</h3>
        <p>
          <strong>Account Information:</strong> When you sign up or log in, we
          collect personal information, such as your name, email address, and
          password.
        </p>
        <p>
          <strong>Contact via WhatsApp:</strong> If you choose to contact us
          through the provided WhatsApp link, any information shared will be
          subject to WhatsApp's own privacy policy.
        </p>

        <h3>1.2 Payment Information</h3>
        <p>
          <strong>Third-Party Payment Services:</strong> Payment details are
          processed through third-party services when you contact us for
          payment. We do not collect or store payment information directly
          within the app.
        </p>

        <h3>1.3 Automatically Collected Information</h3>
        <p>
          <strong>Usage Data:</strong> We may collect information about how you
          access and use the app, including device type, operating system, IP
          address, and app activity data.
        </p>
        <p>
          <strong>Cookies and Similar Technologies:</strong> We may use
          technologies such as cookies to improve your experience.
        </p>
      </section>

      <section className="privacy-section">
        <h2>2. How We Use Your Information</h2>
        <p>We may use the collected information in the following ways:</p>
        <ul>
          <li>To manage user accounts and authentication.</li>
          <li>
            To facilitate communication through WhatsApp for inquiries and
            payments.
          </li>
          <li>
            To display available courses and track course access upon successful
            payment.
          </li>
          <li>To improve the functionality and user experience of the app.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at muhammadnasirpk44@gmail.com.
        </p>
      </section>
    </div>
  );
};

export default Privacy;
