import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-wrapper">
      <div className="about-container">
        <h1>About me</h1>

        <div className="image-gallery">
          <div className="gallery-item">
            <img
              src={process.env.PUBLIC_URL + "/assets/aboutUS-img-1.jpg"}
              alt="Trading Analysis"
              className="gallery-image"
            />
          </div>

          <div className="gallery-item">
            <img
              src={process.env.PUBLIC_URL + "/assets/aboutUS-img-3.jpg"}
              alt="Trading Success"
              className="gallery-image"
            />
          </div>

          <div className="gallery-item">
            <img
              src={process.env.PUBLIC_URL + "/assets/aboutUS-img-5.jpg"}
              alt="Trading Success"
              className="gallery-image"
            />
          </div>
        </div>

        <section className="stats-section">
          <h2>Stats don't lie :)</h2>
          <div className="stats-grid">
            <div className="stat-item">
              <h3>350k</h3>
              <p>YouTube Subscribers</p>
            </div>
            <div className="stat-item">
              <h3>10+</h3>
              <p>Years In Business</p>
            </div>
            <div className="stat-item">
              <h3>1000+</h3>
              <p>Live Trades</p>
            </div>
            <div className="stat-item">
              <h3>100k+</h3>
              <p>Active Daily Users</p>
            </div>
            <div className="stat-item">
              <h3>250k+</h3>
              <p>Telegram Users</p>
            </div>
            <div className="stat-item">
              <h3>1000+</h3>
              <p>Hours of content</p>
            </div>
          </div>
        </section>

        <div className="dark-section">
          <div className="intro-section">
            <p className="intro-text">
              Hello and welcome to my website! My name is Rahman and I am a
              full-time crypto and index options trader with a passion for the
              market and a proven track record of success.
            </p>
            <p className="intro-text">
              I have been involved in the world of currency and cryptocurrency
              for 7 years and have seen firsthand the incredible potential that
              this market holds. Over the years, I have developed a deep
              understanding of the intricacies of the market and have honed my
              skills as a trader to consistently generate profitable returns.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
